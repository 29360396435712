import React, { useEffect, useState } from 'react'
import { Grid, Chip, Toolbar, Divider, Box } from '@mui/material'
import { API, graphqlOperation } from 'aws-amplify'
import * as customQueries from '../../graphql/customQueries'

export const TagFilterBar = (props) => {
  const { tagFilterHandler } = props
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])

  const getTags = async () => {
    const data = await API.graphql(
      graphqlOperation(customQueries.listTagsWithVids)
    )
    setTags(
      data.data.listTags.items.filter((tag) => tag.videos.items.length > 0)
    )
  }

  const handleSelectedTags = (tagName) => {
    if (selectedTags.includes(tagName)) {
      setSelectedTags((prevState) => {
        return prevState.filter((tag) => tag !== tagName)
      })
    } else {
      setSelectedTags((prevState) => [...prevState, tagName])
    }
  }

  useEffect(() => {
    getTags()
  }, [])

  useEffect(() => {
    tagFilterHandler(selectedTags)
  }, [selectedTags])

  return (
    <div>
      <Divider />
      <Box sx={{ display: 'flex', overflow: 'auto', whiteSpace: 'nowrap' }}>
        <Toolbar variant='dense'>
          {tags.map((tag) => (
            <div style={{ padding: 5 }} key={tag.id}>
              <Chip
                label={tag.name}
                color={selectedTags.includes(tag.name) ? 'primary' : 'default'}
                onClick={() => {
                  handleSelectedTags(tag.name)
                }}
              />
            </div>
          ))}
        </Toolbar>
      </Box>
      <Divider />
    </div>
  )
}
