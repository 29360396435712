import React, { useEffect, useState } from 'react'
import { Button, TextField, Grid, Typography } from '@mui/material'
import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../../graphql/mutations'

export const SubmitComment = (props) => {
  const [commentContent, setCommentContent] = useState('')
  const [showCommentHelper, setShowCommentHelper] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const { id, videoTime } = props

  const handleSubmit = () => {
    setDisableSubmit(true)
    if (commentContent === '') {
      setShowCommentHelper(true)
      console.log('Comment is empty, please fill it!')
      setDisableSubmit(false)
      return
    }
    const comment = {
      input: {
        videoID: id,
        content: commentContent,
        atVideoTime: videoTime,
      },
    }
    API.graphql(graphqlOperation(mutations.createComment, comment)).then(
      (response, error) => {
        if (error === undefined) {
          setCommentContent('')
          console.log('Successfully uploaded comment!')
        }
      }
    )
    setDisableSubmit(false)
  }

  useEffect(() => {
    setTimeout(() => {
      if (showCommentHelper === true) {
        setShowCommentHelper(false)
      }
    }, 7000)
  }, [showCommentHelper])

  return (
    <Grid container justifyItems='center' alignItems='center' spacing={2}>
      <Grid item xs={10}>
        <TextField
          variant='filled'
          value={commentContent}
          fullWidth
          onChange={(e) => {
            setCommentContent(e.target.value)
          }}
          placeholder='Enter a comment'
        />
        {showCommentHelper && (
          <Grid item xs>
            <Typography color='error'>
              Please enter a comment before you submit!
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs>
        <Button
          variant='contained'
          color='success'
          disabled={disableSubmit}
          onClick={() => {
            handleSubmit()
          }}>
          Submit
        </Button>
      </Grid>
    </Grid>
  )
}
