export function durationString(time) {
  var hours = Math.floor(time * 0.000277778)
  var minutes = Math.floor(time / 60 - hours * 60)
  var seconds = Math.ceil(time - minutes * 60 - hours * 3600)

  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  if (seconds < 10) {
    seconds = `0${seconds}`
  }

  return hours > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`
}
