import React, { useState } from 'react'
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { UserMenu } from './UserMenu'
import { DrawerMenu } from './DrawerMenu'
import { VideoSearch } from './Search/VideoSearch'
import { useNavigate } from 'react-router-dom'
import DarkModeLogo from '../../img/logoDarkMode.svg'

export const Topbar = (props) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const { title, buttons, menuItems, videoSearch, themeToggleHandler } = props
  const navigate = useNavigate()

  const handleDrawerClose = () => {
    setShowDrawer(false)
  }

  const handleDrawerOpen = () => {
    setShowDrawer(true)
  }

  const handleDrawerToggle = () => {
    setShowDrawer(!showDrawer)
  }

  return (
    <AppBar position='sticky'>
      <Toolbar>
        {menuItems && (
          <div>
            <IconButton
              onClick={() => {
                handleDrawerToggle()
              }}>
              <MenuIcon />
            </IconButton>
            <DrawerMenu
              showDrawer={showDrawer}
              handleOpen={handleDrawerOpen}
              handleClose={handleDrawerClose}
              menuItems={menuItems}
            />
          </div>
        )}

        <Typography variant='h6' sx={{ flexGrow: 1 }}>
          <img
            src={DarkModeLogo}
            onClick={() => navigate('/')}
            style={{
              maxWidth: '5.5rem',
              maxHeight: '5.5rem',
              padding: 10,
              cursor: 'pointer',
            }}
          />
          {title ? title : ''}
        </Typography>

        {videoSearch && <VideoSearch />}
        {buttons?.map((item) => (
          <Button color='inherit' onClick={item.handler}>
            {item.name}
          </Button>
        ))}
        <UserMenu themeToggleHandler={themeToggleHandler} />
      </Toolbar>
    </AppBar>
  )
}
