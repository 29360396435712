/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVodAsset = /* GraphQL */ `
  query GetVodAsset($id: ID!) {
    getVodAsset(id: $id) {
      id
      title
      description
      broadcastDate
      duration
      tags {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      commentCount
      viewCount
      type
      createdAt
      playlists {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      thumbnail
      showVideo
      video {
        id
        token
        fileType
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listVodAssets = /* GraphQL */ `
  query ListVodAssets(
    $filter: ModelVodAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVodAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      videos {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVidJoinTag = /* GraphQL */ `
  query GetVidJoinTag($id: ID!) {
    getVidJoinTag(id: $id) {
      id
      videoID
      tagID
      tagName
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      tag {
        id
        name
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVidJoinTags = /* GraphQL */ `
  query ListVidJoinTags(
    $filter: ModelVidJoinTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVidJoinTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoID
        tagID
        tagName
        video {
          id
          title
          description
          broadcastDate
          duration
          commentCount
          viewCount
          type
          createdAt
          thumbnail
          showVideo
          updatedAt
        }
        tag {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getViewHistory = /* GraphQL */ `
  query GetViewHistory($id: ID!) {
    getViewHistory(id: $id) {
      id
      videoID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      checkpointTimes
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listViewHistorys = /* GraphQL */ `
  query ListViewHistorys(
    $filter: ModelViewHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViewHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoID
        video {
          id
          title
          description
          broadcastDate
          duration
          commentCount
          viewCount
          type
          createdAt
          thumbnail
          showVideo
          updatedAt
        }
        checkpointTimes
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVidJoinPlaylist = /* GraphQL */ `
  query GetVidJoinPlaylist($id: ID!) {
    getVidJoinPlaylist(id: $id) {
      id
      videoID
      playlistID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      playlist {
        id
        title
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVidJoinPlaylists = /* GraphQL */ `
  query ListVidJoinPlaylists(
    $filter: ModelVidJoinPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVidJoinPlaylists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoID
        playlistID
        video {
          id
          title
          description
          broadcastDate
          duration
          commentCount
          viewCount
          type
          createdAt
          thumbnail
          showVideo
          updatedAt
        }
        playlist {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVodAssetPlaylist = /* GraphQL */ `
  query GetVodAssetPlaylist($id: ID!) {
    getVodAssetPlaylist(id: $id) {
      id
      title
      videos {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVodAssetPlaylists = /* GraphQL */ `
  query ListVodAssetPlaylists(
    $filter: ModelVodAssetPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVodAssetPlaylists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      videoID
      content
      atVideoTime
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoID
        content
        atVideoTime
        video {
          id
          title
          description
          broadcastDate
          duration
          commentCount
          viewCount
          type
          createdAt
          thumbnail
          showVideo
          updatedAt
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      videoID
      rating
      owner
      createdAt
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoID
        rating
        owner
        createdAt
        video {
          id
          title
          description
          broadcastDate
          duration
          commentCount
          viewCount
          type
          createdAt
          thumbnail
          showVideo
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideoObject = /* GraphQL */ `
  query GetVideoObject($id: ID!) {
    getVideoObject(id: $id) {
      id
      token
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const listVideoObjects = /* GraphQL */ `
  query ListVideoObjects(
    $filter: ModelVideoObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        fileType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const videoByUploadDate = /* GraphQL */ `
  query VideoByUploadDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVodAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoByUploadDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const viewByDate = /* GraphQL */ `
  query ViewByDate(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelViewHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    viewByDate(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoID
        video {
          id
          title
          description
          broadcastDate
          duration
          commentCount
          viewCount
          type
          createdAt
          thumbnail
          showVideo
          updatedAt
        }
        checkpointTimes
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
