import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  Alert,
  Snackbar,
  ButtonBase,
} from '@mui/material'
import { VideoPlayer } from '../VideoPlayer'
import { Comments } from '../Comments'
import { useParams } from 'react-router'
import awsvideo from '../../aws-video-exports'
import { Recommended } from './Recommended'
import './index.css'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import { Topbar } from '../Topbar/Topbar'
import { Reactions } from '../Reactions'
import { Views } from '../Views'
import uuidv4 from 'uuid/v4'
import { width } from '@mui/system'
import SkelVideoPlayer from '../../util/SkelVideoPlayer'
import { TitleSection } from './TitleSection'

const VideoPage = (props) => {
  const { themeToggleHandler } = props
  const [currentVideo, setCurrentVideo] = useState(null)
  const [currentVideoStartTime, setCurrentVideoStartTime] = useState(0)
  const [currentVideoTime, setCurrentVideoTime] = useState(0)
  const [showTimeOverrideButton, setShowTimeOverrideButton] = useState(false)
  const [shouldOverride, setShouldOverride] = useState(false)
  const [timeOverride, setTimeOverrride] = useState(1)
  const [sources, setSources] = useState([])
  const [username, setUsername] = useState()
  const [videoLoaded, setVideoLoaded] = useState(false)
  const { videoid } = useParams()

  const getUsername = () => {
    Auth.currentAuthenticatedUser().then((resp) => {
      setUsername(resp.username)
    })
  }

  const currentTimeHandler = (time) => {
    setCurrentVideoTime(time)
  }

  const startTimeHandler = (time) => {
    setCurrentVideoStartTime(time)
  }

  const handleShouldOverrideToggle = () => {
    setShouldOverride(!shouldOverride)
  }

  const handleTimeOverride = (time) => {
    setTimeOverrride(time)
  }

  const toggleShowTimeOverrideButton = () => {
    setShowTimeOverrideButton(!showTimeOverrideButton)
  }

  useEffect(() => {
    getUsername()
  }, [])

  useEffect(() => {
    setVideoLoaded(false)
    if (videoid) {
      API.graphql(graphqlOperation(queries.getVodAsset, { id: videoid }))
        .then((resp) => {
          console.log(resp)
          setSources([
            {
              src: `https://${awsvideo.awsOutputVideo}/${resp.data.getVodAsset.video.id}/${resp.data.getVodAsset.video.id}.m3u8${resp.data.getVodAsset.video.token}`,
              type: 'application/x-mpegURL',
            },
          ])
          setCurrentVideo(resp.data.getVodAsset)
          setVideoLoaded(true)
        })
        .catch((e) => console.log(e))
    }
  }, [videoid])

  return (
    <Paper elevation={0} sx={{ minHeight: '100vh' }}>
      <Topbar themeToggleHandler={themeToggleHandler} />
      {videoLoaded && (
        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
          <Grid
            container
            justifyItems='center'
            maxWidth='xl'
            spacing={2}
            sx={{ padding: 1 }}>
            <Grid item xs={12} md={9}>
              <Grid direction='column'>
                <Grid item>
                  <VideoPlayer
                    controls
                    fluid
                    sources={sources}
                    bigPlayButton={true}
                    autoplay
                    token={currentVideo.video.token}
                    timeCallback={currentTimeHandler}
                    startTimeCallback={startTimeHandler}
                    timeOverride={timeOverride}
                    shouldOverride={shouldOverride}
                    handleShouldOverrideToggle={handleShouldOverrideToggle}
                    handleTimeOverride={handleTimeOverride}
                    toggleShowTimeOverrideButton={toggleShowTimeOverrideButton}
                  />
                </Grid>
                <Snackbar
                  open={showTimeOverrideButton}
                  autoHideDuration={6000}
                  onClose={() => toggleShowTimeOverrideButton()}>
                  <Alert
                    severity='info'
                    onClose={() => toggleShowTimeOverrideButton()}>
                    <ButtonBase onClick={() => handleShouldOverrideToggle()}>
                      Resume from Last Known Position?
                    </ButtonBase>
                  </Alert>
                </Snackbar>
                <Grid item>
                  <TitleSection
                    video={currentVideo}
                    startVideoTime={currentVideoStartTime}
                    videoTime={currentVideoTime}
                    username={username}
                    handleTimeOverride={handleTimeOverride}
                    toggleShowTimeOverrideButton={toggleShowTimeOverrideButton}
                  />
                </Grid>
                <Grid item>
                  <Comments
                    id={videoid}
                    title={currentVideo.title}
                    commentCount={currentVideo.commentCount}
                    videoTime={currentVideoTime}
                    username={username}
                    handleShouldOverrideToggle={handleShouldOverrideToggle}
                    handleTimeOverride={handleTimeOverride}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs md={3}>
              <Grid>
                <Recommended currentVideo={currentVideo} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  )
}

export default VideoPage
