import React from 'react'
import { Card, CardContent, Typography, Box } from '@mui/material'
import { useTheme } from '@mui/styles'
import awsvideo from '../../aws-video-exports'
import { durationString } from '../../util/timeHelpers'
import fuzzyTime from 'fuzzy-time'
import './index.css'

const GridCardView = (props) => {
  const theme = useTheme()
  const {
    item: { title, broadcastDate, duration, createdAt },
  } = props

  const readableDate = new Date(broadcastDate)
  const readableUploadDate = fuzzyTime(new Date(createdAt), {
    setMinDays: 1,
    dateFormat: 'simple',
  })

  const readableDuration = durationString(duration)
  const thumbnailSource = `https://${awsvideo.awsOutputVideo}/${props.item.video.id}/${props.item.video.id}.${props.item.thumbnail}.jpg`

  return (
    <Card sx={{ maxWidth: 345 }}>
      <Box>
        <div>
          <img src={thumbnailSource} width={'100%'} />
        </div>
        <div
          className={
            theme.palette.mode === 'dark'
              ? 'text-on-image'
              : 'text-on-image-light'
          }>
          <Typography variant='body2' component='p'>
            {readableDuration}
          </Typography>
        </div>
      </Box>
      <CardContent sx={{ minWidth: '20rem' }}>
        <Typography variant='h6' component='h6' align='left' noWrap>
          {title}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          component='p'
          align='left'
          noWrap>
          {readableDate.toDateString()}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          component='p'
          align='left'
          noWrap>
          {readableUploadDate}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default GridCardView
