import React, { useState, useEffect } from 'react'
import { Box, Drawer, ListItem, List, Typography, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTheme } from '@mui/styles'
import { API, graphqlOperation } from 'aws-amplify'
import { getVodAssetWithoutToken } from '../../graphql/customQueries'
import * as mutations from '../../graphql/mutations'
import awsvideo from '../../aws-video-exports'
import './index.css'

export const SelectThumbnail = (props) => {
  const theme = useTheme()
  const { open, handleClose, videoId } = props
  const [video, setVideo] = useState()
  const [currThumbnail, setCurrThumbnail] = useState()
  const [isUploading, toggleIsUploading] = useState(false)
  const thumbnailArray = [
    '0000000',
    '0000001',
    '0000002',
    '0000003',
    '0000004',
    '0000005',
    '0000006',
    '0000007',
    '0000008',
    '0000009',
  ]

  const getVideo = async () => {
    try {
      const data = await API.graphql({
        query: getVodAssetWithoutToken,
        variables: { id: videoId },
      })
      setVideo(data.data.getVodAsset)
      setCurrThumbnail(data.data.getVodAsset.thumbnail)
    } catch (e) {
      console.error(e)
    }
  }

  const handleThumbnailChange = async () => {
    toggleIsUploading(true)
    const videoDetails = {
      id: video?.id,
      thumbnail: currThumbnail,
    }

    try {
      await API.graphql({
        query: mutations.updateVodAsset,
        variables: { input: videoDetails },
      })
      console.log(`successfully updated thumbnail to ${currThumbnail}`)
      getVideo().then(() => {
        toggleIsUploading(false)
      })

      handleClose()
    } catch (e) {
      console.error(e)
      toggleIsUploading(false)
    }
  }

  useEffect(() => {
    getVideo()
  }, [])

  return (
    <Drawer open={open} onClose={handleClose} anchor={'right'}>
      <LoadingButton
        loading={isUploading}
        variant='contained'
        disabled={currThumbnail === video?.thumbnail}
        onClick={() => {
          handleThumbnailChange()
        }}>
        Save
      </LoadingButton>
      <List>
        {currThumbnail &&
          thumbnailArray.map((thumbnailId) => {
            return thumbnailId === currThumbnail ? (
              <ListItem onClick={() => setCurrThumbnail(thumbnailId)}>
                <Box>
                  <div className='thumbnail-selected'>
                    <Typography>✅</Typography>
                  </div>
                  <div>
                    <img
                      src={`https://${awsvideo.awsOutputVideo}/${video?.video.id}/${video?.video.id}.${thumbnailId}.jpg`}
                      key={thumbnailId}
                      style={{ maxWidth: '17rem' }}
                    />
                  </div>
                </Box>
              </ListItem>
            ) : (
              <ListItem onClick={() => setCurrThumbnail(thumbnailId)}>
                <img
                  src={`https://${awsvideo.awsOutputVideo}/${video?.video.id}/${video?.video.id}.${thumbnailId}.jpg`}
                  key={thumbnailId}
                  style={{ maxWidth: '17rem' }}
                />
              </ListItem>
            )
          })}
      </List>
    </Drawer>
  )
}
