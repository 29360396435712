/* eslint-disable import/order */
import React, { useState, useEffect } from 'react'
import { Grid, ButtonBase, Typography, IconButton } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import GridCardView from '../GridCardView'
import { useNavigate } from 'react-router-dom'

export const GridView = (props) => {
  const { title, videos, queryHandler, isPlaylist } = props
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState()
  const [previousTokens, setPreviousTokens] = useState([])

  useEffect(() => {
    if (queryHandler && isPlaylist === false) {
      queryHandler(nextToken)
    }
  }, [nextToken])

  useEffect(() => {
    if (videos) {
      setNextNextToken(videos.nextToken)
    }
  }, [videos])

  const navigate = useNavigate()

  return (
    <div>
      {title && videos && (
        <Typography variant='h4' gutterBottom>
          {title}
        </Typography>
      )}
      <div>
        <Grid
          direction='row'
          justifyItems='space-evenly'
          alignItems='center'
          spacing={2}
          columns={17}
          sx={{ display: 'flex', whiteSpace: 'nowrap', overflow: 'auto' }}>
          {previousTokens.length > 0 && (
            <Grid item>
              <IconButton
                onClick={() => {
                  setNextToken(previousTokens[previousTokens.length - 1])
                  setPreviousTokens((prevState) => {
                    prevState.pop()
                    return prevState
                  })
                }}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
          )}
          {videos?.items?.map((video) => (
            <Grid item sx={{ padding: 1 }} key={video.id}>
              <ButtonBase
                onClick={() => {
                  navigate(`/Video/${video.id}`)
                }}>
                <GridCardView item={video} />
              </ButtonBase>
            </Grid>
          ))}

          {nextNextToken && nextNextToken !== null && (
            <Grid item>
              <IconButton
                onClick={() => {
                  setPreviousTokens((prevState) => [...prevState, nextToken])
                  setNextToken(nextNextToken)
                }}>
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  )
}
