import React, { useState, useEffect } from 'react'
import {
  DataGridPro,
  LicenseInfo,
  GridToolbarContainer,
  GridEvents,
  useGridApiRef,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro'
import { Button, Box, Paper, Grid } from '@mui/material'
import { API, graphqlOperation } from 'aws-amplify'
import {
  deleteVodAsset,
  deleteVideoObject,
  deleteVidJoinPlaylist,
  deleteVidJoinTag,
} from '../../graphql/mutations'
import * as mutations from '../../graphql/mutations'
import * as customQueries from '../../graphql/customQueries'
import { GenericDialog } from './GenericDialog'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import { SelectThumbnail } from './SelectThumbnail'

const DeleteVideo = () => {
  const apiRef = useGridApiRef()
  const [videos, setVideos] = useState([])
  const [rows, setRows] = useState([])
  const [editItems, setEditItems] = useState({})
  const [thumbnailEditVid, setThumbnailEditVid] = useState('')
  const [showThumbnailModal, toggleThumbnailModal] = useState(false)
  const [loadingVideos, setLoadingVideos] = useState(true)
  const [chosenItems, setChosenItems] = useState([])
  const [showModal, toggleShowModal] = useState(false)
  const [showEditModal, toggleShowEditModal] = useState(false)

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_XGRID_KEY)

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 0.8,
      editable: true,
    },
    {
      field: 'showVideo',
      headerName: 'Show',
      type: 'boolean',
      editable: true,
    },
    {
      field: 'broadcastDate',
      headerName: 'Broadcast',
      flex: 0.8,
      editable: true,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 0.8,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
    },

    { field: 'id', headerName: 'ID', flex: 0.8 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<InsertPhotoIcon />}
          onClick={() => {
            setThumbnailEditVid(params.row.id)
            toggleThumbnailModal(true)
          }}
          label='Edit Thumbnail'
        />,
      ],
    },
  ]

  const fetchVideos = () => {
    setLoadingVideos(true)
    const variables = {
      limit: 25,
    }

    API.graphql(
      graphqlOperation(
        customQueries.listVodAssetsWithoutTokenWithPlaylists,
        variables
      )
    )
      .then((resp) => {
        setVideos(resp.data.listVodAssets.items)

        setLoadingVideos(false)
      })
      .catch((e) => console.error(e))
  }

  useEffect(() => {
    setRows(
      videos?.map((item) => ({
        id: item.id,
        title: item.title,
        createdAt: item.createdAt,
        broadcastDate: item.broadcastDate,
        showVideo: item.showVideo,
      }))
    )
  }, [videos])

  const disconnectPlaylist = async (vidAsset) => {
    console.log('Deleteing asset from playlists')
    vidAsset[0].playlists.items.forEach(async (playlist) => {
      console.log(`...from ${playlist.id}`)
      try {
        await API.graphql(
          graphqlOperation(deleteVidJoinPlaylist, {
            input: { id: playlist.id },
          })
        )
      } catch (e) {
        console.log(e)
      }
    })
  }

  const disconnectTags = async (vidAsset) => {
    console.log('Deleteing asset from tags')
    vidAsset[0].tags.items.forEach(async (tag) => {
      console.log(`...from ${tag.id}`)
      try {
        await API.graphql(
          graphqlOperation(deleteVidJoinTag, {
            input: { id: tag.id },
          })
        )
      } catch (e) {
        console.log(e)
      }
    })
  }

  const deleteVideo = async (videoIndx) => {
    let vidAsset = videos.filter((item) => {
      return item.id === chosenItems[videoIndx]
    })
    const apiName = 'videoRestAPI'
    var path = '/deleteVideo'
    const params = {
      body: {
        videoId: vidAsset[0].video.id,
        vidAssetId: vidAsset[0].id,
        filetype: vidAsset[0].video.fileType,
      },
    }

    try {
      console.log('Deleting from s3...')
      const deleteApiResp = await API.post(apiName, path, params)
      console.log(deleteApiResp)
      if (vidAsset[0].playlists.items.length > 0) {
        await disconnectPlaylist(vidAsset)
      }
      if (vidAsset[0].tags.items.length > 0) {
        await disconnectTags(vidAsset)
      }
      console.log('Deleting video entry')
      await API.graphql(
        graphqlOperation(deleteVideoObject, {
          input: { id: params.body.videoId },
        })
      )
      console.log('Deleting video asset entry')
      await API.graphql(
        graphqlOperation(deleteVodAsset, {
          input: { id: params.body.vidAssetId },
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteItems = async () => {
    if (chosenItems.length > 0) {
      setLoadingVideos(true)
      for (let i = 0; i < chosenItems.length; i++) {
        try {
          await deleteVideo(i)
        } catch (e) {
          console.log(e)
        }
      }
      setTimeout(() => {
        fetchVideos()
      }, 1500)
      handleCloseDeleteModal()
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant='contained'
              // disabled
              color='error'
              disabled={chosenItems.length <= 0}
              onClick={() => toggleShowModal(!showModal)}>
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              disabled={Object.keys(editItems).length <= 0}
              onClick={() => toggleShowEditModal(!showEditModal)}>
              Save Edits
            </Button>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    )
  }

  useEffect(() => {
    fetchVideos()
  }, [])

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      GridEvents.cellEditCommit,
      (params, event) => {
        setEditItems((prevState) => {
          if (prevState[params.id]) {
            return {
              ...prevState,
              [params.id]: [
                ...prevState[params.id].filter(
                  (item) => item.field !== params.field
                ),
                params,
              ],
            }
          }
          return { ...prevState, [params.id]: [params] }
        })
      }
    )
  }, [apiRef])

  const updateItem = async (item) => {
    var updateDetails = {}
    updateDetails.id = item[0].id
    item.forEach((obj) => {
      if (obj.value instanceof Date) {
        updateDetails[obj.field] = obj.value.toISOString()
      } else {
        updateDetails[obj.field] = obj.value
      }
    })

    console.log(updateDetails)

    try {
      await API.graphql({
        query: mutations.updateVodAsset,
        variables: { input: updateDetails },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const updateItems = async () => {
    console.log('Updating items...')
    Object.values(editItems).forEach(async (item) => {
      try {
        await updateItem(item)
      } catch (e) {
        console.error(e)
      }
    })
    console.log('Successfully updated items!')
  }

  const handleCloseEditModal = () => {
    toggleShowEditModal(false)
  }

  const handleCloseThumbnailModal = () => {
    toggleThumbnailModal(false)
  }

  const handleUpdateItems = async () => {
    await updateItems()
    handleCloseEditModal()
    fetchVideos()
  }

  const handleCloseDeleteModal = () => {
    toggleShowModal(false)
  }

  return (
    <Box>
      <GenericDialog
        openModal={showModal}
        toggleModal={toggleShowModal}
        title={'Delete videos?'}
        contentText={
          "Deleted videos will NOT be recoverable and will require reuploading. If you'd like to take the video down without deleting, you can toggle the 'show' column for the corresponding video.\n Are you sure you want to delete these videos?"
        }
        actions={[
          { name: 'Do it.', color: 'error', onClick: handleDeleteItems },
          { name: 'No', color: 'success', onClick: handleCloseDeleteModal },
        ]}
      />
      <GenericDialog
        openModal={showEditModal}
        toggleModal={toggleShowEditModal}
        title={'Edit Video Metadata?'}
        contentText={
          'This will override the current metadata for the edited videos, would you like to continue?'
        }
        actions={[
          { name: 'Yes', color: 'error', onClick: handleUpdateItems },
          { name: 'No', color: 'success', onClick: handleCloseEditModal },
        ]}
      />
      <SelectThumbnail
        open={showThumbnailModal}
        handleClose={handleCloseThumbnailModal}
        videoId={thumbnailEditVid}
        key={thumbnailEditVid}
      />
      <Paper>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
          }}
          apiRef={apiRef}
          editMode='cell'
          pagination
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(selection) => {
            setChosenItems(selection)
          }}
          loading={loadingVideos}
        />
      </Paper>
    </Box>
  )
}

export default DeleteVideo
