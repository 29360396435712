/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateVodAsset = /* GraphQL */ `
  subscription OnCreateVodAsset {
    onCreateVodAsset {
      id
      title
      description
      broadcastDate
      duration
      tags {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      commentCount
      viewCount
      type
      createdAt
      playlists {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      thumbnail
      showVideo
      video {
        id
        token
        fileType
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateVodAsset = /* GraphQL */ `
  subscription OnUpdateVodAsset {
    onUpdateVodAsset {
      id
      title
      description
      broadcastDate
      duration
      tags {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      commentCount
      viewCount
      type
      createdAt
      playlists {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      thumbnail
      showVideo
      video {
        id
        token
        fileType
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteVodAsset = /* GraphQL */ `
  subscription OnDeleteVodAsset {
    onDeleteVodAsset {
      id
      title
      description
      broadcastDate
      duration
      tags {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      commentCount
      viewCount
      type
      createdAt
      playlists {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      thumbnail
      showVideo
      video {
        id
        token
        fileType
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag {
    onCreateTag {
      id
      name
      videos {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag {
    onUpdateTag {
      id
      name
      videos {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag {
    onDeleteTag {
      id
      name
      videos {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVidJoinTag = /* GraphQL */ `
  subscription OnCreateVidJoinTag {
    onCreateVidJoinTag {
      id
      videoID
      tagID
      tagName
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      tag {
        id
        name
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVidJoinTag = /* GraphQL */ `
  subscription OnUpdateVidJoinTag {
    onUpdateVidJoinTag {
      id
      videoID
      tagID
      tagName
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      tag {
        id
        name
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVidJoinTag = /* GraphQL */ `
  subscription OnDeleteVidJoinTag {
    onDeleteVidJoinTag {
      id
      videoID
      tagID
      tagName
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      tag {
        id
        name
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateViewHistory = /* GraphQL */ `
  subscription OnCreateViewHistory {
    onCreateViewHistory {
      id
      videoID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      checkpointTimes
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateViewHistory = /* GraphQL */ `
  subscription OnUpdateViewHistory {
    onUpdateViewHistory {
      id
      videoID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      checkpointTimes
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteViewHistory = /* GraphQL */ `
  subscription OnDeleteViewHistory {
    onDeleteViewHistory {
      id
      videoID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      checkpointTimes
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVidJoinPlaylist = /* GraphQL */ `
  subscription OnCreateVidJoinPlaylist {
    onCreateVidJoinPlaylist {
      id
      videoID
      playlistID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      playlist {
        id
        title
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVidJoinPlaylist = /* GraphQL */ `
  subscription OnUpdateVidJoinPlaylist {
    onUpdateVidJoinPlaylist {
      id
      videoID
      playlistID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      playlist {
        id
        title
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVidJoinPlaylist = /* GraphQL */ `
  subscription OnDeleteVidJoinPlaylist {
    onDeleteVidJoinPlaylist {
      id
      videoID
      playlistID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      playlist {
        id
        title
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVodAssetPlaylist = /* GraphQL */ `
  subscription OnCreateVodAssetPlaylist {
    onCreateVodAssetPlaylist {
      id
      title
      videos {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVodAssetPlaylist = /* GraphQL */ `
  subscription OnUpdateVodAssetPlaylist {
    onUpdateVodAssetPlaylist {
      id
      title
      videos {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVodAssetPlaylist = /* GraphQL */ `
  subscription OnDeleteVodAssetPlaylist {
    onDeleteVodAssetPlaylist {
      id
      title
      videos {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      videoID
      content
      atVideoTime
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      videoID
      content
      atVideoTime
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      videoID
      content
      atVideoTime
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateRating = /* GraphQL */ `
  subscription OnCreateRating {
    onCreateRating {
      id
      videoID
      rating
      owner
      createdAt
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateRating = /* GraphQL */ `
  subscription OnUpdateRating {
    onUpdateRating {
      id
      videoID
      rating
      owner
      createdAt
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteRating = /* GraphQL */ `
  subscription OnDeleteRating {
    onDeleteRating {
      id
      videoID
      rating
      owner
      createdAt
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateVideoObject = /* GraphQL */ `
  subscription OnCreateVideoObject {
    onCreateVideoObject {
      id
      token
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVideoObject = /* GraphQL */ `
  subscription OnUpdateVideoObject {
    onUpdateVideoObject {
      id
      token
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVideoObject = /* GraphQL */ `
  subscription OnDeleteVideoObject {
    onDeleteVideoObject {
      id
      token
      fileType
      createdAt
      updatedAt
    }
  }
`;
