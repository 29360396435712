import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Avatar,
  Tooltip,
  Divider,
} from '@mui/material'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import CancelIcon from '@mui/icons-material/Cancel'
import { Topbar } from '../Topbar/Topbar'
import { Auth, Hub } from 'aws-amplify'
import { CountrySelect } from './CountrySelect'
import { SocialMediaConnection } from './SocialMediaConnection'
import { UserViewHistory } from './UserViewHistory'

const Profile = (props) => {
  const { themeToggleHandler } = props
  const [user, setUser] = useState()
  const [realName, setRealName] = useState('')
  const [country, setCountry] = useState()
  const navigate = useNavigate()
  const attributeChannel = 'userChangeAttributes'

  const getUser = async () => {
    const user = await Auth.currentAuthenticatedUser()

    setUser(user)
  }

  const listenerAttributeChannel = (data) => {
    if (data.payload.event === 'changedAttribute') {
      getUser()
    }
  }

  useEffect(() => {
    getUser()
    Hub.listen(attributeChannel, listenerAttributeChannel)
    return () => {
      Hub.remove(attributeChannel)
    }
  }, [])

  useEffect(() => {
    setRealName(user?.attributes.name)
    if (user?.attributes.hasOwnProperty('address')) {
      setCountry(JSON.parse(user.attributes.address).country)
    }
  }, [user])

  const handleSubmitNewCountry = async () => {
    await Auth.updateUserAttributes(user, {
      'address': JSON.stringify({ country: country.code }),
    })
      .then(() =>
        Hub.dispatch(attributeChannel, {
          event: 'changedAttribute',
          message: `${user.username} has successfully changed their country!`,
        })
      )
      .catch((e) => console.log(e))
  }

  const handleSubmitNewRealName = async () => {
    if (realName !== '') {
      await Auth.updateUserAttributes(user, {
        'name': realName,
      })
        .then((resp) =>
          Hub.dispatch(attributeChannel, {
            event: 'changedAttribute',
            message: `${user.username} has successfully changed their name!`,
          })
        )
        .catch((e) => console.log(e))
    }
  }

  const handleGoHome = () => {
    navigate('/')
  }

  const handleChangeCountry = (value) => {
    setCountry(value)
  }

  return (
    <Paper elevation={0} sx={{ minHeight: '100vh' }}>
      <Topbar themeToggleHandler={themeToggleHandler} />
      {user && (
        <div style={{ padding: 5 }}>
          <Grid container direction='column' spacing={1}>
            <Grid item sx={{ padding: 1 }}>
              <Grid
                container
                item
                direction='row'
                spacing={2}
                justifyItems={'center'}
                alignItems={'center'}>
                <Grid item>
                  <Avatar>{user.username.charAt(0).toUpperCase()}</Avatar>
                </Grid>
                <Grid item>
                  <Typography variant='h4'>{user.username}</Typography>
                </Grid>
                <Grid item>
                  {user.attributes.email_verified ? (
                    <Tooltip title='Email Verified' placement='right'>
                      <VerifiedUserIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title='Email Unverified' placement='right'>
                      <CancelIcon />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider variant='middle' />
            <Grid container item>
              <TextField
                label='Full Name'
                value={realName}
                onChange={(e) => setRealName(e.target.value)}
              />
              <Button
                onClick={() => {
                  handleSubmitNewRealName()
                }}
                variant='outlined'
                color='success'>
                Save
              </Button>
            </Grid>
            <Grid container item>
              <Grid item xs={2}>
                <CountrySelect
                  currentCountry={country}
                  setCurrentCountry={handleChangeCountry}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    handleSubmitNewCountry()
                  }}
                  variant='outlined'
                  color='success'>
                  Save
                </Button>
              </Grid>
            </Grid>
            {/* <Grid item>
              <SocialMediaConnection userAttributes={user?.attributes} />
            </Grid> */}
            <Grid item>
              <UserViewHistory user={user} />
            </Grid>
          </Grid>
        </div>
      )}
    </Paper>
  )
}

export default Profile
