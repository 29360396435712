import React, { useState, useEffect, useMemo } from 'react'
import { Grid, Drawer } from '@mui/material'
import { API, graphqlOperation } from 'aws-amplify'
import * as customQueries from '../../../graphql/customQueries'
import { VideoSearchView } from './VideoSearchView'
import debounce from 'lodash.debounce'

export const VideoSearchModal = (props) => {
  const {
    showModal,
    toggleShowModal,
    Search,
    SearchIconWrapper,
    StyledInputBase,
    SearchIcon,
  } = props
  const [searchQuery, setSearchQuery] = useState('')
  const [videos, setVideos] = useState()

  const handleVideoQuery = () => {
    const params = { filter: { title: { contains: searchQuery } } }

    API.graphql(
      graphqlOperation(customQueries.listVodAssetsWithoutToken, params)
    )
      .then((resp) => {
        setVideos(resp.data.listVodAssets)
      })
      .catch((e) => console.error(e))
  }

  const changeHander = (e) => {
    setSearchQuery(e.target.value)
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHander, 300))

  useEffect(() => {
    handleVideoQuery()
  }, [searchQuery])

  return (
    <Drawer
      open={showModal}
      onClose={() => {
        toggleShowModal(!showModal)
      }}
      anchor={'right'}>
      <Grid
        container
        spacing={2}
        justifyItems='flex-start'
        alignItems='center'
        direction='column'
        sx={{ padding: 1 }}>
        <Grid item>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              autoFocus
              placeholder='Search Videos'
              inputProps={{ 'aria-label': 'search' }}
              onChange={debouncedChangeHandler}
            />
          </Search>
        </Grid>
        <Grid item>
          <VideoSearchView videos={videos} />
        </Grid>
      </Grid>
    </Drawer>
  )
}
