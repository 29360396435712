/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVodAsset = /* GraphQL */ `
  mutation CreateVodAsset(
    $input: CreateVodAssetInput!
    $condition: ModelVodAssetConditionInput
  ) {
    createVodAsset(input: $input, condition: $condition) {
      id
      title
      description
      broadcastDate
      duration
      tags {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      commentCount
      viewCount
      type
      createdAt
      playlists {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      thumbnail
      showVideo
      video {
        id
        token
        fileType
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateVodAsset = /* GraphQL */ `
  mutation UpdateVodAsset(
    $input: UpdateVodAssetInput!
    $condition: ModelVodAssetConditionInput
  ) {
    updateVodAsset(input: $input, condition: $condition) {
      id
      title
      description
      broadcastDate
      duration
      tags {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      commentCount
      viewCount
      type
      createdAt
      playlists {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      thumbnail
      showVideo
      video {
        id
        token
        fileType
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteVodAsset = /* GraphQL */ `
  mutation DeleteVodAsset(
    $input: DeleteVodAssetInput!
    $condition: ModelVodAssetConditionInput
  ) {
    deleteVodAsset(input: $input, condition: $condition) {
      id
      title
      description
      broadcastDate
      duration
      tags {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      commentCount
      viewCount
      type
      createdAt
      playlists {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      thumbnail
      showVideo
      video {
        id
        token
        fileType
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      videos {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      videos {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      videos {
        items {
          id
          videoID
          tagID
          tagName
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVidJoinTag = /* GraphQL */ `
  mutation CreateVidJoinTag(
    $input: CreateVidJoinTagInput!
    $condition: ModelVidJoinTagConditionInput
  ) {
    createVidJoinTag(input: $input, condition: $condition) {
      id
      videoID
      tagID
      tagName
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      tag {
        id
        name
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVidJoinTag = /* GraphQL */ `
  mutation UpdateVidJoinTag(
    $input: UpdateVidJoinTagInput!
    $condition: ModelVidJoinTagConditionInput
  ) {
    updateVidJoinTag(input: $input, condition: $condition) {
      id
      videoID
      tagID
      tagName
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      tag {
        id
        name
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVidJoinTag = /* GraphQL */ `
  mutation DeleteVidJoinTag(
    $input: DeleteVidJoinTagInput!
    $condition: ModelVidJoinTagConditionInput
  ) {
    deleteVidJoinTag(input: $input, condition: $condition) {
      id
      videoID
      tagID
      tagName
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      tag {
        id
        name
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createViewHistory = /* GraphQL */ `
  mutation CreateViewHistory(
    $input: CreateViewHistoryInput!
    $condition: ModelViewHistoryConditionInput
  ) {
    createViewHistory(input: $input, condition: $condition) {
      id
      videoID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      checkpointTimes
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateViewHistory = /* GraphQL */ `
  mutation UpdateViewHistory(
    $input: UpdateViewHistoryInput!
    $condition: ModelViewHistoryConditionInput
  ) {
    updateViewHistory(input: $input, condition: $condition) {
      id
      videoID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      checkpointTimes
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteViewHistory = /* GraphQL */ `
  mutation DeleteViewHistory(
    $input: DeleteViewHistoryInput!
    $condition: ModelViewHistoryConditionInput
  ) {
    deleteViewHistory(input: $input, condition: $condition) {
      id
      videoID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      checkpointTimes
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createVidJoinPlaylist = /* GraphQL */ `
  mutation CreateVidJoinPlaylist(
    $input: CreateVidJoinPlaylistInput!
    $condition: ModelVidJoinPlaylistConditionInput
  ) {
    createVidJoinPlaylist(input: $input, condition: $condition) {
      id
      videoID
      playlistID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      playlist {
        id
        title
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVidJoinPlaylist = /* GraphQL */ `
  mutation UpdateVidJoinPlaylist(
    $input: UpdateVidJoinPlaylistInput!
    $condition: ModelVidJoinPlaylistConditionInput
  ) {
    updateVidJoinPlaylist(input: $input, condition: $condition) {
      id
      videoID
      playlistID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      playlist {
        id
        title
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVidJoinPlaylist = /* GraphQL */ `
  mutation DeleteVidJoinPlaylist(
    $input: DeleteVidJoinPlaylistInput!
    $condition: ModelVidJoinPlaylistConditionInput
  ) {
    deleteVidJoinPlaylist(input: $input, condition: $condition) {
      id
      videoID
      playlistID
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      playlist {
        id
        title
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVodAssetPlaylist = /* GraphQL */ `
  mutation CreateVodAssetPlaylist(
    $input: CreateVodAssetPlaylistInput!
    $condition: ModelVodAssetPlaylistConditionInput
  ) {
    createVodAssetPlaylist(input: $input, condition: $condition) {
      id
      title
      videos {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVodAssetPlaylist = /* GraphQL */ `
  mutation UpdateVodAssetPlaylist(
    $input: UpdateVodAssetPlaylistInput!
    $condition: ModelVodAssetPlaylistConditionInput
  ) {
    updateVodAssetPlaylist(input: $input, condition: $condition) {
      id
      title
      videos {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVodAssetPlaylist = /* GraphQL */ `
  mutation DeleteVodAssetPlaylist(
    $input: DeleteVodAssetPlaylistInput!
    $condition: ModelVodAssetPlaylistConditionInput
  ) {
    deleteVodAssetPlaylist(input: $input, condition: $condition) {
      id
      title
      videos {
        items {
          id
          videoID
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      videoID
      content
      atVideoTime
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      videoID
      content
      atVideoTime
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      videoID
      content
      atVideoTime
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRating = /* GraphQL */ `
  mutation CreateRating(
    $input: CreateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    createRating(input: $input, condition: $condition) {
      id
      videoID
      rating
      owner
      createdAt
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateRating = /* GraphQL */ `
  mutation UpdateRating(
    $input: UpdateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    updateRating(input: $input, condition: $condition) {
      id
      videoID
      rating
      owner
      createdAt
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteRating = /* GraphQL */ `
  mutation DeleteRating(
    $input: DeleteRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    deleteRating(input: $input, condition: $condition) {
      id
      videoID
      rating
      owner
      createdAt
      video {
        id
        title
        description
        broadcastDate
        duration
        tags {
          nextToken
        }
        commentCount
        viewCount
        type
        createdAt
        playlists {
          nextToken
        }
        thumbnail
        showVideo
        video {
          id
          token
          fileType
          createdAt
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createVideoObject = /* GraphQL */ `
  mutation CreateVideoObject(
    $input: CreateVideoObjectInput!
    $condition: ModelVideoObjectConditionInput
  ) {
    createVideoObject(input: $input, condition: $condition) {
      id
      token
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const updateVideoObject = /* GraphQL */ `
  mutation UpdateVideoObject(
    $input: UpdateVideoObjectInput!
    $condition: ModelVideoObjectConditionInput
  ) {
    updateVideoObject(input: $input, condition: $condition) {
      id
      token
      fileType
      createdAt
      updatedAt
    }
  }
`;
export const deleteVideoObject = /* GraphQL */ `
  mutation DeleteVideoObject(
    $input: DeleteVideoObjectInput!
    $condition: ModelVideoObjectConditionInput
  ) {
    deleteVideoObject(input: $input, condition: $condition) {
      id
      token
      fileType
      createdAt
      updatedAt
    }
  }
`;
