import React, { useState, useEffect } from 'react'
import { Grid, Typography, ButtonBase, Button } from '@mui/material'
import { useNavigate } from 'react-router'
import { API, graphqlOperation } from 'aws-amplify'
import fuzzyTime from 'fuzzy-time'
import * as queries from '../../graphql/queries'

export const UserViewHistory = (props) => {
  const { user } = props
  const navigate = useNavigate()
  const [userViews, setUserViews] = useState()
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState()
  const [previousTokens, setPreviousTokens] = useState([])

  const getUserViews = async () => {
    const variables = {
      nextToken: nextToken,
      owner: user.username,
      createdAt: { le: new Date().toISOString() },
      limit: 20,
      sortDirection: 'DESC',
    }
    API.graphql(graphqlOperation(queries.viewByDate, variables))
      .then((resp) => {
        setNextNextToken(resp.data.viewByDate.nextToken)
        setUserViews(resp.data.viewByDate.items)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getUserViews()
  }, [nextToken])

  return (
    <Grid container direction='column'>
      <Grid item xs>
        <Typography>User View History</Typography>
      </Grid>
      {userViews?.map((view) => {
        if (view.video) {
          return (
            <ButtonBase
              onClick={() => {
                navigate(`/Video/${view.video.id}`)
              }}>
              <Grid item xs>
                <Typography>
                  {view.video.title} -{' '}
                  {fuzzyTime(new Date(view.createdAt), {
                    setMinDays: 1,
                    dateFormat: 'simple',
                  })}
                </Typography>
              </Grid>
            </ButtonBase>
          )
        }
      })}
      {nextNextToken && (
        <Button
          onClick={() => {
            setPreviousTokens((prevState) => [...prevState, nextToken])
            setNextToken(nextNextToken)
          }}>
          Next Page
        </Button>
      )}
      {previousTokens.length > 0 && (
        <Button
          onClick={() => {
            setNextToken(previousTokens[previousTokens.length - 1])
            setPreviousTokens((prevState) => {
              prevState.pop()
              return prevState
            })
          }}>
          Prev Page
        </Button>
      )}
    </Grid>
  )
}
