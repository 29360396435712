export const listTagsWithVids = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        videos {
          items {
            id
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const listVodAssetsWithoutToken = /* GraphQL */ `
  query ListVodAssets(
    $filter: ModelVodAssetFilterInput
    $limit: Int
    $nextToken: String
    $filterTags: ModelVidJoinTagFilterInput
    $nextTokenTags: String
  ) {
    listVodAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        broadcastDate
        duration
        commentCount
        viewCount
        showVideo
        thumbnail
        tags(filter: $filterTags, nextToken: $nextTokenTags) {
          items {
            tagName
          }
          nextToken
        }
        video {
          id
          createdAt
          updatedAt
          fileType
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listVodAssetsWithoutTokenWithPlaylists = /* GraphQL */ `
  query ListVodAssets(
    $filter: ModelVodAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVodAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        broadcastDate
        duration
        commentCount
        viewCount
        showVideo
        thumbnail
        tags {
          items {
            id
            tagName
          }
          nextToken
        }
        playlists {
          items {
            id
            playlistID
          }
          nextToken
        }
        video {
          id
          createdAt
          updatedAt
          fileType
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getVodAssetWithoutToken = /* GraphQL */ `
  query GetVodAsset($id: ID!) {
    getVodAsset(id: $id) {
      id
      title
      description
      broadcastDate
      duration
      commentCount
      viewCount
      type
      createdAt
      showVideo
      thumbnail
      tags {
        items {
          tagName
        }
        nextToken
      }
      video {
        id
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`

export const getVideoObjectToken = /* GraphQL */ `
  query GetVideoObject($id: ID!) {
    getVideoObject(id: $id) {
      id
      token
    }
  }
`

export const videoByUploadDateWithoutToken = /* GraphQL */ `
  query VideoByUploadDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVodAssetFilterInput
    $limit: Int
    $nextToken: String
    $filterTags: ModelVidJoinTagFilterInput
    $nextTokenTags: String
  ) {
    videoByUploadDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        broadcastDate
        duration
        commentCount
        viewCount
        type
        createdAt
        showVideo
        thumbnail
        tags(filter: $filterTags, nextToken: $nextTokenTags) {
          items {
            tagName
          }
          nextToken
        }
        video {
          id
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`
export const listVodAssetPlaylistsForVideos = /* GraphQL */ `
  query ListVodAssetPlaylists(
    $filter: ModelVodAssetPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVodAssetPlaylists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        videos {
          items {
            video {
              id
              title
              description
              broadcastDate
              duration
              commentCount
              viewCount
              type
              createdAt
              updatedAt
              thumbnail
              showVideo
              video {
                id
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVodAssetPlaylistWithoutTokens = /* GraphQL */ `
  query GetVodAssetPlaylist($id: ID!) {
    getVodAssetPlaylist(id: $id) {
      id
      title
      videos {
        items {
          video {
            id
            title
            description
            broadcastDate
            duration
            commentCount
            viewCount
            type
            createdAt
            updatedAt
            showVideo
            thumbnail
            tags {
              items {
                tagName
              }
              nextToken
            }
            video {
              id
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
