import React from 'react'
import { ViewCounter } from './ViewCount'
import { ViewUpdater } from './ViewUpdater'

export const Views = (props) => {
  const {
    video,
    videoTime,
    startVideoTime,
    username,
    handleTimeOverride,
    toggleShowTimeOverrideButton,
  } = props

  return (
    <div>
      <ViewCounter video={video} />
      <ViewUpdater
        video={video}
        startVideoTime={startVideoTime}
        videoTime={videoTime}
        username={username}
        handleTimeOverride={handleTimeOverride}
        toggleShowTimeOverrideButton={toggleShowTimeOverrideButton}
      />
    </div>
  )
}
