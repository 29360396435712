import React from 'react'
import { ButtonBase, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import GridCardView from '../../GridCardView'

export const VideoSearchView = (props) => {
  const { videos } = props
  const navigate = useNavigate()

  return (
    <Grid container spacing={2} direction='column'>
      {videos?.items.map((video) => (
        <Grid item sx={{ maxWidth: '17rem' }}>
          <ButtonBase
            onClick={() => {
              navigate(`/Video/${video.id}`)
            }}>
            <GridCardView item={video} />
          </ButtonBase>
        </Grid>
      ))}
    </Grid>
  )
}
