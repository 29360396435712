import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { deleteComment } from '../../graphql/mutations'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

export const DeleteComment = (props) => {
  const { id } = props

  const handleDeleteComment = () => {
    const input = { input: { id: id } }

    API.graphql(graphqlOperation(deleteComment, input))
      .then((resp) => {
        console.log('Successfully deleted comment: ', resp)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <IconButton
      size='small'
      onClick={() => {
        handleDeleteComment()
      }}>
      <DeleteIcon fontSize='inherit' />
    </IconButton>
  )
}
