import React, { useState, useEffect } from 'react'
import {
  Button,
  Menu,
  MenuItem,
  Divider,
  ListItemText,
  ListItemIcon,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import LogoutIcon from '@mui/icons-material/Logout'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { Auth } from 'aws-amplify'
import { SignOut } from '../Auth/AuthFunc'
import { useNavigate } from 'react-router-dom'

export const UserMenu = (props) => {
  const theme = useTheme()
  const { themeToggleHandler } = props
  const [username, setUsername] = useState()
  const [isAdmin, setIsAdmin] = useState(false)
  const navigate = useNavigate()

  const handleAdminClick = () => {
    navigate('/admin')
  }

  const isAdminCheck = async () => {
    const user = await Auth.currentAuthenticatedUser()

    setIsAdmin(
      user.signInUserSession.accessToken.payload['cognito:groups']?.includes(
        'Admin'
      )
    )
  }

  useEffect(() => {
    isAdminCheck()
  }, [])

  const getUsername = async () => {
    const user = await Auth.currentAuthenticatedUser()

    setUsername(user.username)
  }

  useEffect(() => {
    getUsername()
  }, [])

  return (
    <div>
      {username && (
        <PopupState variant='popover' popupId='demo-popup-menu'>
          {(popupState) => (
            <React.Fragment>
              <Button
                variant='contained'
                color='primary'
                startIcon={<AccountCircleIcon />}
                {...bindTrigger(popupState)}>
                {username}
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    popupState.close()
                    navigate('/Profile')
                  }}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText>Settings</ListItemText>
                </MenuItem>

                {isAdmin && (
                  <MenuItem
                    onClick={() => {
                      popupState.close()
                      handleAdminClick()
                    }}>
                    <ListItemIcon>
                      <AdminPanelSettingsIcon />
                    </ListItemIcon>
                    <ListItemText>Admin</ListItemText>
                  </MenuItem>
                )}
                <MenuItem disableRipple onClick={themeToggleHandler}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={theme.palette.mode === 'dark' ? true : false}
                        />
                      }
                      label={
                        theme.palette.mode === 'dark' ? (
                          <DarkModeIcon />
                        ) : (
                          <LightModeIcon />
                        )
                      }
                    />
                  </FormGroup>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    SignOut()
                    popupState.close()
                    navigate('/login')
                  }}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      )}
    </div>
  )
}
