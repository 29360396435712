/* eslint-disable import/order */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import { Grid, Paper, Typography, Tabs, Tab, Box } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { Topbar } from '../Topbar/Topbar'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const routes = ['uploadvideo', 'editvideo']

const Admin = (props) => {
  let location = useLocation()
  const initRoutIndx =
    location.pathname.split('/').length <= 2
      ? 0
      : routes.indexOf(
          location.pathname.split('/')[location.pathname.split('/').length - 1]
        )
  let navigate = useNavigate()
  const [routeIndx, setRouteIndx] = useState(initRoutIndx)
  const [routeName, setRouteName] = useState(routes[initRoutIndx])
  const { themeToggleHandler } = props

  const handleChange = (event, newValue) => {
    setRouteIndx(newValue)
  }

  useEffect(() => {
    setRouteName(routes[routeIndx])
  }, [routeIndx])

  useEffect(() => {
    navigate(`/admin/${routeName}`)
  }, [routeName])

  return (
    <Grid>
      <Topbar title='Admin Panel' themeToggleHandler={themeToggleHandler} />
      <Paper elevation={0} square>
        <Box>
          <Tabs
            value={routeIndx}
            variant='scrollable'
            scrollButtons='auto'
            onChange={handleChange}
            sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tab
              icon={<FileUploadIcon />}
              label='Upload'
              id={routes.indexOf('uploadvideo')}
            />
            <Tab
              label='Edit'
              icon={<ModeEditIcon />}
              id={routes.indexOf('editvideo')}
            />
          </Tabs>
          <TabPanel value={routeIndx} index={routeIndx}>
            <Outlet />
          </TabPanel>
        </Box>
      </Paper>
    </Grid>
  )
}

export default Admin
