/* eslint-disable import/order */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, Fragment } from 'react'
import uuidv4 from 'uuid/v4'
import FilePicker from '../FilePicker'
import {
  Modal,
  LinearProgress,
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from '@mui/material'

import Amplify, { Auth, API, graphqlOperation, Storage } from 'aws-amplify'
import awsvideoconfig from '../../aws-video-exports'
import {
  createVodAsset,
  createVideoObject,
  createVodAssetPlaylist,
  createVidJoinPlaylist,
  createTag,
  createVidJoinTag,
} from '../../graphql/mutations'
import * as queries from '../../graphql/queries'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { TagSelector } from './TagSelector'
import { PlaylistSelector } from './PlaylistSelector'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const UploadVideo = (props) => {
  const [showUpload, setShowUpload] = useState(false)
  const [state, setState] = useState({
    titleVal: '',
    descVal: '',
    dateVal: new Date(),
    groups: [],
    progress: 0,
    duration: 0.0,
    showVideo: true,
  })
  const [completedSteps, setCompletedSteps] = useState([])
  const [tags, setTags] = useState([])
  const [playlist, setPlaylist] = useState([])
  const [createdVodAssetId, setCreatedVodAssetId] = useState()
  const [startCreatePlaylist, setStartCreatePlaylist] = useState(false)
  const [startCreateTag, setStartCreateTag] = useState(false)
  const [showSuccessAlert, toggleSuccessAlert] = useState(false)
  const [showErrorAlert, toggleErrorAlert] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [creatingAsset, toggleCreatingAsset] = useState(false)
  const [skipped, setSkipped] = useState(new Set())
  const AUTO_HIDE_TIME = 6000

  const handleSetTags = (tags) => {
    setTags(tags)
  }

  const handleSetPlaylists = (playlists) => {
    setPlaylist(playlists)
  }

  const myCallback = (dataFromChild) => {
    var video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = function() {
      window.URL.revokeObjectURL(video.src)
      var duration = video.duration
      setState({
        ...state,
        file: dataFromChild,
        fileName: dataFromChild.name,
        duration: duration,
      })
    }

    video.src = URL.createObjectURL(dataFromChild)
  }

  const steps = [
    {
      name: 'Select File',
      optional: false,
      alertMessage: 'Please select a video file',
      react: (
        <div>
          <Grid item>
            <FilePicker callbackFromParent={myCallback} />
          </Grid>
          <Grid item>
            {state.fileName ? (
              <Typography>{state.fileName}</Typography>
            ) : (
              <Typography>No File Selected</Typography>
            )}
          </Grid>
        </div>
      ),
    },
    {
      name: 'Title',
      optional: false,
      alertMessage: 'Please enter a title',
      react: (
        <Grid item container spacing={2} direction='column'>
          <Grid item>
            <TextField
              variant='filled'
              value={state.titleVal}
              onChange={(e) => handleChange(e)}
              placeholder='Enter a title'
              label='Title'
              fullWidth
              inputProps={{
                type: 'text',
                name: 'titleVal',
              }}
            />
          </Grid>
          <Grid item>
            {state.fileName && (
              <Grid
                item
                container
                spacing={2}
                alignItems={'center'}
                justifyItems={'center'}>
                <Grid item>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setState({
                        ...state,
                        titleVal: state.fileName.split('.')[0],
                      })
                    }}>
                    Use Suggested Title
                  </Button>
                </Grid>
                <Grid item>
                  <Typography>{state.fileName.split('.')[0]}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'Description',
      optional: false,
      alertMessage: 'Please enter a description',
      react: (
        <Grid item>
          <TextField
            variant='filled'
            placeholder='Enter a description'
            label='Description'
            fullWidth
            inputProps={{
              className: 'desTextA',
              name: 'descVal',
              cols: 100,
            }}
            multiline
            rows={4}
            value={state.descVal}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
      ),
    },
    {
      name: 'Playlist',
      optional: true,
      react: (
        <Grid item>
          <PlaylistSelector handleSetPlaylists={handleSetPlaylists} />
        </Grid>
      ),
    },
    {
      name: 'Tags',
      optional: true,
      react: (
        <Grid item>
          <TagSelector handleSetTags={handleSetTags} />
        </Grid>
      ),
    },
    {
      name: 'Broadcast Date',
      optional: false,
      react: (
        <Grid item>
          <Typography variant='b1'>Broadcast Date</Typography>
          <DatePicker
            todayButton='Today'
            selected={state.dateVal}
            maxDate={new Date()}
            onChange={(date) => handleDateChange(date)}
          />
        </Grid>
      ),
    },
    {
      name: 'Extra Settings',
      optional: true,
      react: (
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.showVideo}
                  onChange={(e) =>
                    setState((prevState) => {
                      return { ...prevState, showVideo: e.target.checked }
                    })
                  }
                />
              }
              label='Show video on upload? (This can be changed in Edit tab)'
            />
          </FormGroup>
        </Grid>
      ),
    },
  ]

  useEffect(() => {
    const region = Amplify._config.aws_project_region
    Auth.currentSession().then((data) => {
      const groups = data.idToken.payload['cognito:groups']
      if (groups) {
        setState({ ...state, groups: data.idToken.payload['cognito:groups'] })
      }
    })

    Storage.configure({
      AWSS3: {
        bucket: awsvideoconfig.awsInputVideo,
        region,
        customPrefix: {
          public: '',
        },
      },
    })
  }, [])

  const handleChange = (event) => {
    const { value } = event.target
    const { name } = event.target
    setState({ ...state, [name]: value })
  }

  const handleDateChange = (date) => {
    setState({ ...state, dateVal: date })
    console.log(date)
  }

  const addToTag = async (tagName, vodID) => {
    const params = { filter: { name: { eq: tagName } } }

    API.graphql(graphqlOperation(queries.listTags, params)).then(
      async (resp) => {
        console.log(resp.data.listTags)
        var tagID
        if (resp.data.listTags.items.length > 0) {
          // update existing tag if it exists
          console.log('Updating existing tag')
          tagID = resp.data.listTags.items[0].id
        } else {
          // create a new one
          console.log('creating new tag')
          const newTag = {
            input: {
              name: tagName,
            },
          }

          tagID = await API.graphql(graphqlOperation(createTag, newTag))
            .then((data) => {
              return data.data.createTag.id
            })
            .catch((e) => {
              console.error(e)
            })
          console.log(tagID)
        }

        const newVidJoinTag = {
          input: {
            videoID: vodID,
            tagID: tagID,
            tagName: tagName,
          },
        }

        console.log(newVidJoinTag)

        API.graphql(graphqlOperation(createVidJoinTag, newVidJoinTag)).then(
          (data) => {
            console.log('Successfully added video to tag!')
          }
        )
      }
    )
  }

  const addToPlaylist = async (playlistTitle, vodID) => {
    const params = { filter: { title: { eq: playlistTitle } } }
    // console.log(params)

    API.graphql(graphqlOperation(queries.listVodAssetPlaylists, params)).then(
      async (resp) => {
        console.log(resp.data.listVodAssetPlaylists)
        var playlistID
        if (resp.data.listVodAssetPlaylists.items.length > 0) {
          // update existing playlist if it exists
          console.log('Updating existing playlist')
          playlistID = resp.data.listVodAssetPlaylists.items[0].id
        } else {
          // create a new one
          console.log('creating new playlist')
          const newPlaylist = {
            input: {
              title: playlistTitle,
            },
          }

          playlistID = await API.graphql(
            graphqlOperation(createVodAssetPlaylist, newPlaylist)
          )
            .then((data) => {
              return data.data.createVodAssetPlaylist.id
            })
            .catch((e) => {
              console.error(e)
            })
          console.log(playlistID)
        }
        const newVidJoinPlaylist = {
          input: {
            videoID: vodID,
            playlistID: playlistID,
          },
        }

        console.log(newVidJoinPlaylist)

        API.graphql(
          graphqlOperation(createVidJoinPlaylist, newVidJoinPlaylist)
        ).then((data) => {
          console.log('Successfully added video to playlist!')
        })
      }
    )
  }

  const submitFormHandler = () => {
    // Insert Location 6
    // Location 6

    const uuid = uuidv4()
    const {
      titleVal,
      descVal,
      dateVal,
      file,
      fileName,
      duration,
      showVideo,
    } = state
    const fileExtension = fileName.split('.')
    const videoObject = {
      input: {
        id: uuid,
        fileType: fileExtension[fileExtension.length - 1],
      },
    }

    API.graphql(graphqlOperation(createVideoObject, videoObject)).then(
      (response, error) => {
        if (error === undefined) {
          const videoAsset = {
            input: {
              title: titleVal,
              description: descVal,
              vodAssetVideoId: uuid,
              broadcastDate: dateVal.toISOString(),
              duration: duration,
              type: 'Video',
              showVideo: showVideo,
            },
          }
          API.graphql(graphqlOperation(createVodAsset, videoAsset))
            .then((resp) => {
              setCreatedVodAssetId(resp.data.createVodAsset.id)
            })
            .catch((e) => {
              console.error(e)
            })
          setShowUpload(true)
          Storage.put(
            `${uuid}.${fileExtension[fileExtension.length - 1]}`,
            file,
            {
              progressCallback(progress) {
                const { loaded, total } = progress
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
                setState({
                  ...state,
                  progress: (loaded / total) * 100,
                })
              },
              contentType: 'video/*',
            }
          )
            .then(() => {
              console.log(`Successfully Uploaded: ${uuid}`)
              setShowUpload(false)
              toggleSuccessAlert(!showSuccessAlert)
              setStartCreatePlaylist(true)
              setStartCreateTag(true)
              toggleCreatingAsset(false)
            })
            .catch((err) => {
              console.log(`Error: ${err}`)
              setShowUpload(false)
              toggleErrorAlert(!showErrorAlert)
              toggleCreatingAsset(false)
            })
        }
      }
    )
  }

  const isStepOptional = (index) => {
    return steps[index].optional
  }

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const isStepFailed = (index) => {
    if (completedSteps.includes(steps[index].name) === false) {
      return false
    }

    switch (steps[index].name) {
      case 'Select File':
        return state.hasOwnProperty('file') === false
      case 'Title':
        return state.titleVal === ''
      case 'Description':
        return state.descVal === ''
    }
    return false
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setCompletedSteps((prevState) => [...prevState, steps[activeStep].name])
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setCompletedSteps((prevState) => {
      prevState.pop()
      return prevState
    })
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleDisableCreate = () => {
    for (var i = 0; i < steps.length; i++) {
      if (isStepFailed(i)) return true
    }
    return false
  }

  useEffect(() => {
    if (startCreatePlaylist) {
      playlist?.forEach((item) => {
        addToPlaylist(item, createdVodAssetId)
      })
    }
  }, [startCreatePlaylist])

  useEffect(() => {
    if (startCreateTag) {
      tags?.forEach((item) => {
        addToTag(item, createdVodAssetId)
      })
    }
  }, [startCreateTag])

  return (
    <Grid container justifyContent='center' sx={{ padding: 1 }}>
      <br />
      <Modal open={showUpload} onClose={() => setShowUpload(false)}>
        <Box sx={modalStyle}>
          <Typography sx={{ color: 'white' }}>
            Uploading({Math.floor(state.progress)}%)
          </Typography>
          <LinearProgress variant='determinate' value={state.progress} />
        </Box>
      </Modal>
      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={AUTO_HIDE_TIME}
        onClose={() => toggleSuccessAlert(!showSuccessAlert)}>
        <Alert
          severity='success'
          onClose={() => toggleSuccessAlert(!showSuccessAlert)}>
          Video "{state.titleVal}" was successfully uploaded!
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorAlert}
        autoHideDuration={AUTO_HIDE_TIME}
        onClose={() => toggleErrorAlert(!showErrorAlert)}>
        <Alert
          severity='error'
          onClose={() => toggleErrorAlert(!showErrorAlert)}>
          Oops... Something went wrong, check the console.
        </Alert>
      </Snackbar>
      <Box sx={{ width: '100%', maxWidth: 1000 }}>
        <Stepper
          activeStep={activeStep}
          sx={{ overflow: 'auto', whitespace: 'nowrap' }}>
          {steps.map((obj, index) => {
            const stepProps = {}
            const labelProps = {}
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant='caption' noWrap>
                  Optional
                </Typography>
              )
            }
            if (isStepFailed(index)) {
              labelProps.optional = (
                <Typography variant='caption' color='error' noWrap>
                  {obj.alertMessage}
                </Typography>
              )

              labelProps.error = true
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={obj.name} {...stepProps}>
                <StepLabel {...labelProps}>{obj.name}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            {steps[activeStep].react}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color='inherit'
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color='inherit' onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              {activeStep === steps.length - 1 ? (
                <Button
                  color='success'
                  disabled={handleDisableCreate() || creatingAsset}
                  onClick={() => {
                    toggleCreatingAsset(true)
                    submitFormHandler()
                  }}>
                  Create
                </Button>
              ) : (
                <Button onClick={handleNext}>Next</Button>
              )}
            </Box>
          </Fragment>
        )}
      </Box>
    </Grid>
  )
}

export default UploadVideo
