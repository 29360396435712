import React from 'react'
import { Grid, Typography, Divider, Chip } from '@mui/material'
import { Views } from '../Views'
import fuzzyTime from 'fuzzy-time'

export const TitleSection = (props) => {
  const {
    video,
    startVideoTime,
    videoTime,
    username,
    handleTimeOverride,
    toggleShowTimeOverrideButton,
  } = props

  return (
    <Grid container direction='column'>
      <Grid
        item
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={1}>
        <Grid item>
          <Typography variant='h5' noWrap>
            {video.title}
          </Typography>
        </Grid>
        <Grid item>
          <Views
            video={video}
            startVideoTime={startVideoTime}
            videoTime={videoTime}
            username={username}
            handleTimeOverride={handleTimeOverride}
            toggleShowTimeOverrideButton={toggleShowTimeOverrideButton}
          />
        </Grid>
        <Grid item>
          <Typography variant='b2' noWrap>
            {fuzzyTime(new Date(video.broadcastDate), {
              setMinDays: 1,
              dateFormat: 'simple',
              on: ' ',
            })}
          </Typography>
        </Grid>
        {video.tags.items.length > 0 && (
          <Grid item container spacing={1}>
            {video.tags.items?.map((tag) => (
              <Grid item>
                <Chip label={tag.tagName} color={'default'} />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      <Grid item>
        <Divider variant='middle' />
      </Grid>
      <Grid item>
        <Typography variant='b1' noWrap>
          {video.description}
        </Typography>
      </Grid>
    </Grid>
  )
}
