import React from 'react'
import {
  Authenticator,
  SignIn,
  SignUp,
  ConfirmSignUp,
  Greetings,
  VerifyContact,
} from 'aws-amplify-react'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { lightTheme } from '../Router/index'
import { ThemeProvider } from '@mui/material'

const Login = () => {
  const navigate = useNavigate()

  const handleAuthStateChange = (state) => {
    console.log(state)
    if (state === 'signedIn') {
      navigate('/')
    }
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <Paper square sx={{ minHeight: '100vh' }}>
        <Authenticator hideDefault={true} onStateChange={handleAuthStateChange}>
          <SignIn />
          <SignUp />
          <ConfirmSignUp />
          <VerifyContact />
          <Greetings />
        </Authenticator>
      </Paper>
    </ThemeProvider>
  )
}

export default Login
