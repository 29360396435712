import React, { useEffect, useState } from 'react'
import { Typography, Grid, ButtonBase } from '@mui/material'
import { API, graphqlOperation } from 'aws-amplify'
import * as customQueries from '../../graphql/customQueries'
import { useNavigate } from 'react-router-dom'
import { TagFilterBar } from '../TagFilterBar'
import GridCardView from '../GridCardView'

export const Recommended = (props) => {
  const navigate = useNavigate()
  const [items, setItems] = useState()
  const [tagFilter, setTagFilter] = useState()
  const { currentVideo } = props

  const handleTagsInFilter = (tags) => {
    if (tags.length > 0) {
      var tagMap = tags.map((tag) => {
        return { tagName: { eq: tag } }
      })
      setTagFilter({ or: tagMap })
    } else {
      setTagFilter()
    }
  }

  const getReccomendedVideos = () => {
    const variables = { filter: { id: { ne: currentVideo.id } }, limit: 5 }

    if (tagFilter) {
      variables.filterTags = tagFilter
    }

    API.graphql(
      graphqlOperation(customQueries.listVodAssetsWithoutToken, variables)
    )
      .then((resp) => {
        if (tagFilter) {
          setItems(
            resp.data.listVodAssets.items.filter(
              (video) => video.tags.items.length > 0
            )
          )
        } else {
          setItems(resp.data.listVodAssets.items)
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  useEffect(() => getReccomendedVideos(), [tagFilter])

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant='h6'>Recommended</Typography>
      </Grid>
      <Grid item xs={12}>
        <TagFilterBar tagFilterHandler={handleTagsInFilter} />
      </Grid>
      {items?.map((video) => (
        <Grid item>
          <ButtonBase
            aria-label={video.title}
            onClick={() => {
              navigate(`/Video/${video.id}`)
            }}>
            <GridCardView item={video} />
          </ButtonBase>
        </Grid>
      ))}
    </Grid>
  )
}
