import React from 'react'
import { Grid } from '@mui/material'
import { CommentsBox } from './CommentsBox'
import { SubmitComment } from './SubmitComment'

export const Comments = (props) => {
  const {
    id,
    commentCount,
    username,
    videoTime,
    handleShouldOverrideToggle,
    handleTimeOverride,
  } = props

  return (
    <Grid container direction='column' justifyItems='center'>
      <Grid item xs>
        <SubmitComment id={id} videoTime={videoTime} />
      </Grid>
      <Grid item xs>
        <CommentsBox
          id={id}
          commentCount={commentCount}
          username={username}
          handleShouldOverrideToggle={handleShouldOverrideToggle}
          handleTimeOverride={handleTimeOverride}
        />
      </Grid>
    </Grid>
  )
}
