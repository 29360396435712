import React, { useState, useEffect, useMemo } from 'react'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import debounce from 'lodash.debounce'

export const PlaylistSelector = (props) => {
  const { handleSetPlaylists } = props
  const [playlistNames, setPlaylistNames] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const getPlaylistNames = () => {
    const params = { filter: { title: { contains: searchQuery } } }

    API.graphql(graphqlOperation(queries.listVodAssetPlaylists, params))
      .then((resp) => {
        setPlaylistNames(
          resp.data.listVodAssetPlaylists.items.map((item) => ({
            value: item.title,
          }))
        )
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const changeHandler = (e) => {
    setSearchQuery(e.target.value)
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [])

  useEffect(() => {
    getPlaylistNames()
  }, [searchQuery])

  return (
    <Autocomplete
      filterOptions={(x) => x}
      multiple
      options={playlistNames.map((option) => option.value)}
      freeSolo
      onChange={(event, newValue) => handleSetPlaylists(newValue)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant='outlined' label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant='filled'
          label='Playlist'
          placeholder='Add to a playlist?'
          value={searchQuery}
          onChange={debouncedChangeHandler}
        />
      )}
    />
  )
}
