import React, { useEffect, useState } from 'react'
import { GridView } from '../GridView'
import { API, graphqlOperation } from 'aws-amplify'
import * as customQueries from '../../graphql/customQueries'
import { Paper, Stack, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import './App.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { Topbar } from '../Topbar/Topbar'
import { TagFilterBar } from '../TagFilterBar'
import GridLayout from 'react-grid-layout'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

function App(props) {
  const { themeToggleHandler } = props
  const [latestUploads, setLatestUploads] = useState()
  const [videoPlaylists, setVideoPlaylists] = useState()
  const [tagFilter, setTagFilter] = useState()

  const handleTagsInFilter = (tags) => {
    if (tags.length > 0) {
      var tagMap = tags.map((tag) => {
        return { tagName: { eq: tag } }
      })
      setTagFilter({ or: tagMap })
    } else {
      setTagFilter()
    }
  }

  const handleGetLatestUploads = (token) => {
    const variables = {
      type: 'Video',
      sortDirection: 'DESC',
      limit: 5,
      nextToken: token,
      filter: { showVideo: { eq: true } },
    }

    if (tagFilter) {
      variables.filterTags = tagFilter
    }

    API.graphql(
      graphqlOperation(customQueries.videoByUploadDateWithoutToken, variables)
    )
      .then((resp) => {
        if (tagFilter) {
          setLatestUploads({
            items: resp.data.videoByUploadDate.items.filter(
              (video) => video.tags.items.length > 0
            ),
            nextToken: resp.data.videoByUploadDate.nextToken,
          })
        } else {
          setLatestUploads(resp.data.videoByUploadDate)
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  // const filterPlaylists = () => {
  //   if (latestUploads) {
  //     setLatestUploads((prevState) => {
  //       const vidList = prevState.items?.filter((video) => {
  //         return video.tags.items
  //           .map((tag) => filter.includes(tag.tag.name))
  //           .includes(true)
  //       })
  //       prevState.items = vidList
  //       console.log(prevState)
  //       return prevState
  //     })
  //   }
  // }

  const handleGetVideoPlaylists = (token) => {
    const variables = {
      nextToken: token,
    }

    API.graphql(
      graphqlOperation(customQueries.listVodAssetPlaylistsForVideos, variables)
    )
      .then((resp) => {
        setVideoPlaylists({
          nextToken: resp.data.listVodAssetPlaylists.nextToken,
          items: resp.data.listVodAssetPlaylists.items.map((item) => ({
            id: item.id,
            createdAt: item.createdAt,
            title: item.title,
            updatedAt: item.updatedAt,
            videos: {
              items: item.videos.items.map((video) => video.video),
              nextToken: item.videos.nextToken,
            },
          })),
        })
      })
      .catch((e) => {
        console.error(e)
      })
  }

  // const handlePaginateVideoPlaylist = (playlistId, token) => {
  //   const variables = {
  //     id: playlistId,
  //     limit: 5,
  //     nextToken: token,
  //   }

  //   API.graphql(
  //     graphqlOperation(
  //       customQueries.getVodAssetPlaylistWithoutTokens,
  //       variables
  //     )
  //   )
  //     .then((resp) => {
  //       setVideoPlaylists((prevState) => {
  //         console.log(resp)
  //         const index = prevState.items.findIndex(
  //           (item) => item.id == playlistId
  //         )
  //         prevState.items[index] = {
  //           id: resp.data.getVodAssetPlaylist.id,
  //           createdAt: resp.data.getVodAssetPlaylist.createdAt,
  //           title: resp.data.getVodAssetPlaylist.title,
  //           updatedAt: resp.data.getVodAssetPlaylist.updatedAt,
  //           videos: {
  //             items: resp.data.getVodAssetPlaylist.videos.items.map(
  //               (video) => video.video
  //             ),
  //             nextToken: resp.data.getVodAssetPlaylist.videos.nextToken,
  //           },
  //         }
  //         return prevState
  //       })
  //     })
  //     .catch((e) => console.error(e))
  // }

  useEffect(() => {
    handleGetVideoPlaylists()
  }, [])

  useEffect(() => {
    handleGetLatestUploads()
  }, [tagFilter])

  return (
    <Paper elevation={0} sx={{ minHeight: '100vh' }}>
      <Topbar videoSearch={true} themeToggleHandler={themeToggleHandler} />
      <TagFilterBar tagFilterHandler={handleTagsInFilter} />

      <Stack spacing={2} divider={<Divider variant='middle' />}>
        {/* <GridLayout className='layout'> */}
        <Item elevation={0}>
          <GridView
            title='Latest Uploads'
            videos={latestUploads}
            queryHandler={handleGetLatestUploads}
            isPlaylist={false}
          />
        </Item>
        {videoPlaylists?.items?.map((playlist) => {
          if (playlist.videos.items.length > 0) {
            return (
              <Item elevation={0} key={playlist.id}>
                <GridView
                  title={playlist.title}
                  videos={playlist.videos}
                  isPlaylist={true}
                />
              </Item>
            )
          }
        })}
        {/* </GridLayout> */}
      </Stack>
    </Paper>
  )
}

// Insert Location 8
export default App
