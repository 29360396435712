import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'

function sortTimeDesc(a, b) {
  return b.createdAt < a.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
}

export const ViewUpdater = (props) => {
  const {
    video,
    videoTime,
    startVideoTime,
    username,
    handleTimeOverride,
    toggleShowTimeOverrideButton,
  } = props
  const [viewMade, setViewMade] = useState(false)
  const [currentViewData, setCurrentViewData] = useState()
  const [updateView, setUpdateView] = useState(false)

  const handleMakeView = () => {
    if (
      videoTime - startVideoTime > Math.max(video.duration * 0.05, 10) &&
      viewMade === false
    ) {
      setViewMade(true)
      const viewHistory = {
        input: {
          checkpointTimes: [videoTime],
          videoID: video.id,
          owner: username,
          createdAt: new Date().toISOString(),
        },
      }
      API.graphql(graphqlOperation(mutations.createViewHistory, viewHistory))
        .then((resp) => {
          setCurrentViewData(resp.data.createViewHistory)
          console.log('Successfully uploaded ViewHistory: ', resp)
        })
        .catch((e) => console.log(e))
    }
  }

  const handleUpdateView = () => {
    if (viewMade && updateView) {
      const updateCheckpoints = {
        id: currentViewData.id,
        checkpointTimes: [...currentViewData.checkpointTimes, videoTime],
      }

      API.graphql(
        graphqlOperation(mutations.updateViewHistory, {
          input: updateCheckpoints,
        })
      )
        .then((resp) => {
          setCurrentViewData(resp.data.updateViewHistory)
          console.log('Successfully added checkpoint: ', resp)
          setUpdateView(false)
        })
        .catch((e) => console.log(e))
    }
  }

  const userHasViewAlready = () => {
    const filter = {
      and: [
        { videoID: { eq: video.id } },
        { owner: { eq: username } },
        {
          createdAt: {
            ge: new Date(
              new Date().getTime() - 6 * 60 * 60 * 1000
            ).toISOString(),
          },
        },
      ],
    }

    API.graphql(graphqlOperation(queries.listViewHistorys, { filter: filter }))
      .then((result) => {
        if (result.data.listViewHistorys.items.length > 0) {
          var latestTime = result.data.listViewHistorys.items.sort(
            sortTimeDesc
          )[0]
          //   latestTime.checkpointTimes.sort((a, b) => a - b)
          setCurrentViewData(latestTime)
          handleTimeOverride(
            latestTime.checkpointTimes[latestTime.checkpointTimes.length - 1]
          )
          toggleShowTimeOverrideButton()
          setViewMade(true)
        } else {
          setViewMade(false)
        }
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    setTimeout(() => {
      if (videoTime < video.duration - 1 && currentViewData !== undefined) {
        setUpdateView(true)
      } else {
        setUpdateView(false)
      }
    }, 3000)
  }, [viewMade, updateView])

  useEffect(() => {
    if (username) {
      userHasViewAlready()
    }
  }, [username])

  useEffect(() => {
    if (viewMade === false) {
      handleMakeView()
    }
  })

  return <div></div>
}
