import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, Box } from '@mui/material'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { DeleteComment } from './DeleteComment'
import * as queries from '../../graphql/queries'
import * as subscriptions from '../../graphql/subscriptions'
import fuzzyTime from 'fuzzy-time'
import './index.css'

function sortTimeDesc(a, b) {
  return b.createdAt < a.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
}

function sortTimeAsc(a, b) {
  return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0
}

function sortVideoTimeAsc(a, b) {
  return a.atVideoTime < b.atVideoTime
    ? -1
    : a.atVideoTime > b.atVideoTime
    ? 1
    : 0
}

function sortVideoTimeDesc(a, b) {
  return b.atVideoTime < a.atVideoTime
    ? -1
    : b.atVideoTime > a.atVideoTime
    ? 1
    : 0
}

export const CommentsBox = (props) => {
  const {
    id,
    commentCount,
    username,
    handleShouldOverrideToggle,
    handleTimeOverride,
  } = props
  const [commentAmt, setCommentAmt] = useState(commentCount)
  const [comments, setComments] = useState([])
  const [currentSort, setCurrentSort] = useState(() => sortVideoTimeAsc)
  const [isAdmin, setIsAdmin] = useState(false)

  const isAdminCheck = async () => {
    Auth.currentAuthenticatedUser().then((resp) => {
      const groups =
        resp.signInUserSession.accessToken.payload['cognito:groups']
      if (groups?.includes('Admin')) {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    })
  }

  useEffect(() => {
    isAdminCheck()
  }, [])

  const subscribeToCreateComments = () => {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateComment, {
        filter: { videoID: { eq: id } },
      })
    ).subscribe({
      next: ({ value }) => {
        setCommentAmt((prevState) => {
          return prevState + 1
        })
        setComments((prevState) => {
          return [...prevState, value.data.onCreateComment].sort(currentSort)
        })
      },
      error: (error) => console.warn(error),
    })
  }

  const subscribeToDeleteComments = () => {
    return API.graphql(
      graphqlOperation(subscriptions.onDeleteComment, {
        filter: { videoID: { eq: id } },
      })
    ).subscribe({
      next: ({ value }) => {
        setCommentAmt((prevState) => {
          return prevState - 1
        })
        setComments((prevState) => {
          return prevState.filter((item) => {
            return item.id !== value.data.onDeleteComment.id
          })
        })
      },
    })
  }

  const getComments = async () => {
    API.graphql(
      graphqlOperation(queries.listComments, {
        filter: { videoID: { eq: id } },
      })
    ).then((result) => {
      setComments(result.data.listComments.items.sort(currentSort))
    })
  }

  const handleChangeSort = (sortFunc) => {
    setCurrentSort(() => sortFunc)
    handleSortComments(sortFunc)
  }

  const handleSortComments = (sortType) => {
    setComments((prevState) => {
      return [...prevState].sort(sortType)
    })
  }

  useEffect(() => {
    getComments()
    const subCreateObj = subscribeToCreateComments()
    const subDeleteObj = subscribeToDeleteComments()
    return () => {
      subCreateObj.unsubscribe()
      subDeleteObj.unsubscribe()
    }
  }, [])

  return (
    <Grid
      container
      direction='column'
      sx={{ borderStyle: 'solid', padding: 1 }}
      key={id}>
      <Grid item xs>
        <Typography>
          {commentAmt}{' '}
          {commentAmt > 1 || commentAmt <= 0 ? 'Comments' : 'Comment'}
        </Typography>
      </Grid>

      <Grid item xs>
        <Button
          onClick={() => {
            handleChangeSort(sortVideoTimeAsc)
          }}>
          Video Time Asc
        </Button>
        <Button
          onClick={() => {
            handleChangeSort(sortVideoTimeDesc)
          }}>
          Video Time Desc
        </Button>
        <Button
          onClick={() => {
            handleChangeSort(sortTimeDesc)
          }}>
          Date Desc
        </Button>
        <Button
          onClick={() => {
            handleChangeSort(sortTimeAsc)
          }}>
          Date Asc
        </Button>
      </Grid>
      <Box height={'10rem'} overflow='auto' className='hideScrollbar'>
        {comments.map((comment) => (
          <Grid item xs>
            <Typography>
              {comment.owner} - {comment.content} -{' '}
              {fuzzyTime(comment.createdAt)} - at{' '}
              <Button
                onClick={() => {
                  handleTimeOverride(comment.atVideoTime)
                  handleShouldOverrideToggle()
                }}>
                {Math.round(comment.atVideoTime)} sec{' '}
              </Button>
              {(isAdmin || comment.owner === username) && (
                <DeleteComment id={comment.id} />
              )}
            </Typography>
          </Grid>
        ))}
      </Box>
    </Grid>
  )
}
