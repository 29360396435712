/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react'
import videojs from 'video.js'
import './index.css'

export const VideoPlayer = (props) => {
  const {
    startTimeCallback,
    timeCallback,
    timeOverride,
    shouldOverride,
    handleShouldOverrideToggle,
    toggleShowTimeOverrideButton,
  } = props
  const videoNode = useRef()
  const [player, setPlayer] = useState()

  useEffect(() => {
    setPlayer(
      videojs(videoNode.current, props, function onPlayerReady() {
        console.log('onPlayerReady', player)
      })
    )
  }, [])

  useEffect(() => {
    if (player) {
      startTimeCallback(player.currentTime())
      return () => {
        player.dispose()
      }
    }
  }, [player])

  useEffect(() => {
    if (player && shouldOverride && timeOverride) {
      player.currentTime(timeOverride)
      handleShouldOverrideToggle()
      toggleShowTimeOverrideButton()
    }
  }, [player, shouldOverride, timeOverride])

  return (
    <div>
      <div data-vjs-player>
        <video
          ref={videoNode}
          className='video-js vjs-big-play-centered'
          onTimeUpdate={() => timeCallback(player.currentTime())}
        />
      </div>
    </div>
  )
}
