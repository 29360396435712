import React, { useState, useEffect } from 'react'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Paper } from '@mui/material'
import { Hub, Auth } from 'aws-amplify'
import Login from '../Login/Login'
import App from '../App'
import Admin from '../Admin'
import UploadVideo from '../Admin/UploadVideo'
import VideoPage from '../VideoPage'
import Profile from '../Profile/Profile'
import { OnboardingDialog } from '../OnboardModal'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AuthChecker, AdminChecker } from './AuthChecker'
import DeleteVideo from '../Admin/DeleteVideo'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

export const lightTheme = createTheme({
  palatte: {
    mode: 'light',
  },
})

const Routing = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [theme, setTheme] = useState(
    process.env.REACT_APP_SITE_COLOR === 'dark' ? darkTheme : lightTheme
  )

  useEffect(() => {
    prefersDarkMode ? setTheme(darkTheme) : setTheme(lightTheme)
  }, [prefersDarkMode])

  const handleThemeToggle = () => {
    if (theme === darkTheme) {
      setTheme(lightTheme)
    } else {
      setTheme(darkTheme)
    }
  }

  return (
    <div>
      <Router>
        <ThemeProvider theme={theme}>
          <Paper elevation={0} square sx={{ minHeight: '100vh' }}>
            <Routes>
              <Route element={<AuthChecker />}>
                <Route
                  path='/'
                  element={<App themeToggleHandler={handleThemeToggle} />}
                />
                <Route
                  path='/Video/:videoid'
                  element={<VideoPage themeToggleHandler={handleThemeToggle} />}
                />
                <Route
                  path='/Profile'
                  element={<Profile themeToggleHandler={handleThemeToggle} />}
                />

                <Route element={<AdminChecker />}>
                  <Route
                    path='/Admin'
                    element={<Admin themeToggleHandler={handleThemeToggle} />}>
                    <Route path='uploadvideo' element={<UploadVideo />} />
                    <Route path='editvideo' element={<DeleteVideo />} />
                  </Route>
                </Route>
              </Route>
              <Route path='/login' element={<Login />} />
            </Routes>
          </Paper>
        </ThemeProvider>
      </Router>
    </div>
  )
}

export default Routing
