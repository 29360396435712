import React from 'react'
import {
  Toolbar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

export const DrawerMenu = (props) => {
  const { menuItems, handleClose, showDrawer } = props

  return (
    <Drawer
      variant='persistent'
      open={showDrawer}
      onClose={() => {
        handleClose()
      }}>
      <Toolbar sx={{ justifyContent: 'flex-end' }}>
        <IconButton
          onClick={() => {
            handleClose()
          }}>
          <KeyboardArrowLeftIcon />
        </IconButton>
      </Toolbar>
      <List>
        {menuItems?.map((item) => (
          <ListItem>
            <ListItemButton
              onClick={() => {
                item.handler()
              }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}
