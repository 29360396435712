import React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'

export const GenericDialog = (props) => {
  const { title, contentText, actions, openModal, toggleModal } = props

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          toggleModal(!openModal)
        }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions?.map((action) => (
            <Button
              color={action.color ? action.color : 'primary'}
              onClick={() => action.onClick()}>
              {action.name}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  )
}
