import React, { useState, useEffect } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { Auth } from 'aws-amplify'

export const AuthChecker = () => {
  const [isAuth, setIsAuth] = useState()
  let location = useLocation()

  const checkAuthStatus = async () => {
    try {
      await Auth.currentAuthenticatedUser()
      setIsAuth(true)
    } catch (e) {
      setIsAuth(false)
    }
  }

  useEffect(() => {
    checkAuthStatus()
  }, [])

  if (isAuth === undefined) {
    return null
  }

  if (!isAuth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} />
  } else if (isAuth) {
    return <Outlet />
  }
}

export const AdminChecker = () => {
  const [isAdmin, setIsAdmin] = useState()
  let location = useLocation()

  const checkAdminStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (
        user.signInUserSession.accessToken.payload['cognito:groups'].includes(
          'Admin'
        )
      ) {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    } catch (e) {
      setIsAdmin(false)
    }
  }

  useEffect(() => {
    checkAdminStatus()
  }, [])

  if (isAdmin === undefined) {
    return null
  }

  if (!isAdmin) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/admin' state={{ from: location }} />
  } else if (isAdmin) {
    return <Outlet />
  }
}
