import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { API, graphqlOperation } from 'aws-amplify'
import * as subscriptions from '../../graphql/subscriptions'

export const ViewCounter = (props) => {
  const { video } = props
  const [viewCount, setViewCount] = useState()

  const getInitialViewCount = () => {
    setViewCount(video.viewCount)
  }

  const subViewCount = () => {
    const filter = {
      videoID: { eq: video.id },
    }

    return API.graphql(
      graphqlOperation(subscriptions.onCreateViewHistory, { filter: filter })
    ).subscribe({
      next: ({ value }) => {
        setViewCount((prevState) => {
          return prevState + 1
        })
      },
    })
  }

  useEffect(() => {
    if (video) {
      getInitialViewCount()
      const subObj = subViewCount()
      return () => {
        subObj.unsubscribe()
      }
    }
  }, [video])

  return <Typography variant='b2'>{viewCount} Views</Typography>
}
